.modalCard {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: none !important;
}

.table {
  width: 100% !important;;
}

.table th,td {
  border: none !important;
  padding: 10px 0px !important;
}