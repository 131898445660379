.titleWrapper {
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 5px;
  font-weight: 600;
  border: 1px solid #BDBDBD;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 0.125rem 0.75rem;
  margin-left: 1rem;
  line-height: 30px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
}

.contentContainer {
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.tableWrapper {
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.tableWrapper .ant-card {
  flex-grow: 1;
  height: 100%;
}

.cardWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 58px;
}

.subtitleWrapper {
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin: 0 15% 5rem 15%;
  flex: 1;
}

.subtitleWrapperModal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  margin-top: 1rem;
  flex: 1;
}

.subtitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 100%;
}

.subtitleTitle {
  font-weight: 600;
  font-size: 14px;
}

.subtitleValue {
  font-size: 24px;
  font-weight: 600;
}

.subtitle {
  font-size: 10px;
}

.subtitleRevenue {
  font-size: 10px;
  font-weight: 600;
  color: #313B5E;
}

.projectIcon {
  height: 24px;
  width: 24px;
}

.projectName {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

/* .contentContainer {
  justify-content: space-between;
  height: 100%;
  width: 100%;
} */

.rightColumn {
  height: 100%;
}

.affix {
  height: 100%;
}

.icon {
  height: 32px;
  width: 32px;
}

.divider {
  height: 150px;
}

.noMargin {
  margin: 0;
}

.marginTop {
  margin-top: 20px;
}

.subtitleData {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  color: #828282;
  margin-top: 1rem;
}

.subtitleDataLeft {
  display: flex;
  flex-direction: column;
}

.subtitleDataRight {
  font-weight: 600;
  margin-left: 2rem;
}

.iconBtn {
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #828282;
}
