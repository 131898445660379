@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

:root {
    scrollbar-color: #bdbdbd transparent;
}

.ant-btn-default {
  background-color: #fff;
}

.ant-btn-primary.submit {
  background-color: #5a72b1;
}

.ant-btn-primary.save-btn {
  background-color: #5a72b1;
}

.dashboard .dashboard-container .table {
  width: 100%;
}
.dashboard .dashboard-container .kpi {
  height: 100%;
}
.dashboard .dashboard-container .kpi .kpi-container {
  margin-top: 12px;
}
.dashboard .dashboard-container .kpi .ant-card-body {
  padding-top: 8px;
}
.dashboard .dashboard-container .kpi .title {
  color: #313b5e;
  font-weight: 600;
  font-size: 15px;
  line-height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard .dashboard-container .kpi .icon {
  font-size: 30px;
  color: #313b5e;
}
.dashboard .dashboard-container .kpi .value {
  font-size: 29px;
  font-weight: 600;
  line-height: 43px;
  color: #313b5e;
}
.dashboard .dashboard-container .kpi .label {
  color: #313b5e;
  margin-bottom: 8px;
  font-size: 1.2rem;
  line-height: 14px;
  text-transform: uppercase;
}
.dashboard .dashboard-container .kpi .summary-single {
  display: flex;
  align-items: center;
}
.dashboard .dashboard-container .kpi .summary-single .diff-value {
  background-color: #2f3272;
  color: #fff;
  border-radius: 7px;
  padding: 3px 6px;
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 17px;
  margin-right: 8px;
}
.dashboard .dashboard-container .kpi .summary-single .diff-label {
  color: #313b5e;
  font-size: 1.2rem;
  line-height: 14px;
}
.dashboard .dashboard-container .kpi .summary-multiple {
  margin-right: 4px;
}
.dashboard .dashboard-container .kpi .summary-multiple .diff-value {
  background-color: #2f3272;
  color: #fff;
  border-radius: 7px;
  padding: 3px 6px;
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 17px;
}
.dashboard .dashboard-container .kpi .summary-multiple .diff-label {
  color: #313b5e;
  font-size: 1.2rem;
  line-height: 14px;
  text-align: center;
}
.dashboard .dashboard-container .floor-overview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
}
.dashboard .dashboard-container .floor-overview tbody td {
  border-bottom: 0px;
}
.dashboard .dashboard-container .floor-overview .ant-table {
  color: #313b5e;
}

.ant-select {
  color: #313b5e;
  font-weight: 900;
  text-align: center;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #313b5e;
  font-weight: bold;
  text-align: center;
  background-color: #f8f8f8;
}

.ant-select-selection-item {
  font-weight: bold;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1700px) {
  .ant-select-selection-item {
    max-width: 20rem;
  }
}

@media (max-width: 1600px) {
  .ant-select-selection-item {
    max-width: 12rem;
  }
}

@media (max-width: 1440px) {
  .ant-select-selection-item {
    max-width: 6rem;
  }
}

.g2-tooltip {
  background-color: #ffffff !important;
  opacity: 1 !important;
}

.bm-tooltip {
  padding: 12px 0;
  min-width: 100px;
}
.bm-tooltip .bmt-title {
  color: #313b5e;
  font-family: Poppins, Medium;
  font-size: 14px;
  margin-bottom: 8px;
}
.bm-tooltip .bmt-items .bmt-item {
  display: flex;
}
.bm-tooltip .bmt-items .bmt-item div:last-of-type {
  margin-left: auto;
}
.bm-tooltip .bmt-items .bmt-item .bmt-color {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.bm-tooltip .bmt-items .bmt-item .bmt-label {
  padding: 6px 0;
  color: #313b5e;
  font-family: Poppins, Light;
  font-size: 1.2rem;
  margin-right: 6px;
}
.bm-tooltip .bmt-items .bmt-item .bmt-value {
  padding: 6px 0;
  color: #313b5e;
  font-family: Poppins, Bold;
  font-size: 14px;
  float: right;
}

.bm-tooltip-2 .bmt-title {
  color: #313b5e;
  font: normal normal 600 15px Poppins;
}
.bm-tooltip-2 .bmt-subtitle {
  color: #313b5e;
  font: normal normal medium 1.2rem Poppins;
}
.bm-tooltip-2 .bmt-content {
  color: #313b5e;
  font: normal normal 600 20px/31px Poppins;
}
.bm-tooltip-2 .bmt-content .bmt-color {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}

.global-loading-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.bm-info-btn {
  color: #868c98;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.settings {
  width: 100%;
  color: #313b5e !important;
  font-family: Poppins, Medium;
}

.settings .ant-select-selector {
  border-radius: 15px;
  border: 1px solid #bdbdbd !important;
}

.settings .ant-select-selection-item {
  padding-left: 10px !important;
  min-width: 140px;
}

.pagination-segmented {
  font-weight: 600;
  background-color: #F1F1F1;
}

.pagination-segmented .ant-pagination-item * {
  color: #828282 !important;
}

.pagination-segmented .ant-pagination-item:hover {
  border-radius: 100%;
}

.pagination-segmented .ant-pagination-prev *:hover {
  border-radius: 100% !important;
}

.pagination-segmented .ant-pagination-next *:hover {
  border-radius: 100% !important;
}

.pagination-segmented .ant-pagination-item-active {
  border-radius: 100% !important;
  border: none !important;
  background-color: white;
  box-shadow: 1px 0px 4px 1px #00000026;
}

.pagination-segmented .ant-pagination-item-active * {
  color: #313b5e !important;
}

.ant-segmented {
  font-weight: 600;
  border-radius: 25px !important;
  border: 1px solid #bdbdbd;
  background-color: #F1F1F1;
}
.ant-segmented-item {
  border-radius: 25px !important;
}
.ant-segmented-item-selected {
  box-shadow: 2px 0px 10px 2px #00000026;
}
.ant-segmented-item-label {
  color: #828282 !important;
  font-family: Poppins, Medium;
}

.ant-segmented-item-selected .ant-segmented-item-label {
  color: #313b5e !important;
  font-family: Poppins, Medium;
}

.ant-segmented-thumb {
  border-radius: 25px !important;
  box-shadow: 2px 0px 10px 2px #00000026;
}

.settings-item {
  color: #313b5e !important;
}
.settings-item::after {
  border-bottom: 0 !important;
}

.ant-popover-inner {
  border-radius: 16px;
}

.ant-modal-content {
  border-radius: 16px !important;
}

.overview-loading-spin-wrapper {
  width: 100px;
  display: inline-block;
  text-align: center;
}

.price-list .ant-segmented {
  border-radius: 15px;
  border: 1px solid #bdbdbd !important;
  background-color: #F1F1F1;
}

.price-list .ant-segmented-item {
  border-radius: 15px;
}

.price-list .ant-segmented-item-label {
  color: #828282 !important;
  font-family: Poppins, Medium;
}

.price-list .ant-segmented-item-selected .ant-segmented-item-label {
  color: #313b5e !important;
  font-family: Poppins, Medium;
}

.price-list .price-list-container {
  width: 100%;
}
.price-list .selected-row {
  background: #f2f3f4;
}

.price-list .price-list-graphic .buildings-map {
  grid-auto-rows: auto;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}
@media (min-width: 768px) {
  .price-list .price-list-graphic .buildings-map {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (min-width: 992px) {
  .price-list .price-list-graphic .buildings-map {
    grid-template-columns: repeat(2, 1fr);
  }
}
.price-list .price-list-graphic .buildings-map .building-wrapper {
  display: flex;
  margin: 3px;
}
.price-list
  .price-list-graphic
  .buildings-map
  .building-wrapper
  .building-container {
  width: 100%;
}
.price-list .price-list-graphic .buildings-map .building-wrapper .title {
  text-align: center;
  margin-bottom: 6px;
  color: #a6a6a6;
  font-weight: 600;
  font-size: 24px;
}
.price-list
  .price-list-graphic
  .buildings-map
  .building-wrapper
  .floors-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
}
.price-list
  .price-list-graphic
  .buildings-map
  .building-wrapper
  .floors-wrapper
  .floors-label {
  width: 20px;
  color: #a6a6a6;
  font-weight: bolder;
  margin: auto 0;
  transform-origin: 0 0;
  transform: rotate(270deg);
}
.price-list
  .price-list-graphic
  .buildings-map
  .building-wrapper
  .floors-wrapper
  .floors
  .floor {
  display: flex;
  margin-bottom: 15px;
  border-radius: 15px;
}
.price-list
  .price-list-graphic
  .buildings-map
  .building-wrapper
  .floors-wrapper
  .floors
  .floor
  .number {
  margin: 14px 12px;
  font-size: 18px;
  font-weight: 600;
  color: #a6a6a6;
  width: 12px;
}
.price-list
  .price-list-graphic
  .buildings-map
  .building-wrapper
  .floors-wrapper
  .floors
  .floor
  .flats {
  margin: auto 0;
  display: flex;
  flex-wrap: wrap;
}
.price-list
  .price-list-graphic
  .buildings-map
  .building-wrapper
  .floors-wrapper
  .floors
  .floor
  .flats
  .flat {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  margin: 3px;
  padding: 4px;
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  height: 51px;
  width: 51px;
  color: #313b5e;
}
.price-list
  .price-list-graphic
  .buildings-map
  .building-wrapper
  .floors-wrapper
  .floors
  .floor
  .flats
  .flat.selected {
  outline: 3px solid #a6a6a6;
}
.price-list
  .price-list-graphic
  .buildings-map
  .building-wrapper
  .floors-wrapper
  .floors
  .floor
  .flats
  .flat.disabled {
  background-color: #fff;
  color: #d9d9d9;
  font-weight: 400;
}
.price-list
  .price-list-graphic
  .buildings-map
  .building-wrapper
  .floors-wrapper
  .floors
  .floor
  .flats
  .flat.detail {
  font-size: 1.2rem;
  line-height: 18px;
  font-weight: 600;
  height: 70px;
  width: 128px;
  border: 1px solid #bdbdbd;
}
.price-list
  .price-list-graphic
  .buildings-map
  .building-wrapper
  .floors-wrapper
  .floors
  .floor
  .flats
  .flat.detail.disabled {
  background-color: #fff;
  color: #d9d9d9;
  font-weight: 400;
}
.price-list .price-list-graphic .legend {
  display: flex;
  flex-wrap: wrap;
  margin: 24px auto 12px;
}
.price-list .price-list-graphic .legend .legend-item {
  margin-right: 24px;
  line-height: 24px;
  padding: 12px;
  display: flex;
  color: #a6a6a6;
}
.price-list .price-list-graphic .legend .legend-item .color-box {
  border-radius: 5px;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  border: 1px solid #bdbdbd;
}

.availability {
  background-color: #d9d9d9;
}
.availability.sold {
  background-color: #d9d9d9;
}
.availability.reserved {
  background-color: #fffb9d;
}
.availability.available {
  background-color: #ffffff;
}
.current-price {
  color: #828282;
}
.price.increasing {
  --bg: #4bc37b;
  background-color: #4bc37b;
}
.price.increasing-low {
  --bg: #4bc37b80;
  background-color: #4bc37b80;
}
.price.stable {
  --bg: #fff;
  background-color: #fff;
}
.price.decreasing-low {
  --bg: #ea5a5a80;
  background-color: #ea5a5a80;
}
.price.decreasing {
  --bg: #ea5a5a;
  background-color: #ea5a5a;
}

.analytics.decreasing-low {
  --bg: #ea5a5a;
  background-color: #ea5a5a;
}
.analytics.decreasing {
  --bg: #ea5a5a80;
  background-color: #ea5a5a80;
}
.analytics.increasing-low {
  --bg: #4bc37b;
  background-color: #4bc37b;
}
.analytics.increasing {
  --bg: #4bc37b80;
  background-color: #4bc37b80;
}
.analytics.stable {
  --bg: #fff;
  background-color: #fff;
}

.reserved-icon {
  color: #313b5e;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #d9d9d9;
  text-align: center;
  margin-right: 4px;
  margin-left: 8px;
}

.reserved-icon.disabled {
  background-color: #fff;
  color: #d9d9d9;
}

.flat-detail-row {
  min-height: 18px;
}
.flat-detail-row.light {
  font-weight: 400;
}

.flat-detail-divider {
  border-right: 1px solid #313b5e;
  height: 52px;
}
.flat-detail-divider.disabled {
  border-right: 1px solid #d9d9d9;
}

.layout-filter-btn {
  display: flex;
  color: #313b5e;
  font-weight: 600;
  cursor: pointer;
}

.ant-popover-title,
.ant-table-thead > tr > th.ant-table-cell {
  color: #313b5e;
}

.unit-detail {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.unit-detail > .ant-card-body {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}
.unit-detail.detail-page {
  height: 100%;
}
@media (max-width: 1600px) {
  .unit-detail.detail-page {
    max-width: 50vw;
  }
}
@media (min-width: 1600px) {
  .unit-detail.detail-page {
    max-width: 33.3333333333vw;
  }
}
.unit-detail .new-price {
  padding: 6px;
  background-color: #f1f1f1;
  border-radius: 7px;
}
.unit-detail .current-price {
  padding: 6px;
  border-radius: 7px;
}
.unit-detail .see-detail {
  font-size: 16px;
  text-align: center;
  justify-content: center;
}
.unit-detail .see-detail button {
  font-size: 20px;
}
.unit-detail .insights .title {
  margin: 12px 0 0 6px;
  font-size: 16px;
}
.unit-detail .insights .insights-container {
  display: flex;
  flex-wrap: wrap;
}

.price-list-detail .wrapper {
  width: 100%;
  height: 100%;
}
.price-list-detail .wrapper .price-breakdown {
  height: 100%;
}

.login {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.login .logo {
  text-align: center;
  margin-bottom: 24px;
}
.login .logo img {
  height: 64px;
  width: 65px;
}
.login .form {
  border-radius: 10px;
  box-shadow: 0px 0px 25px 10px rgba(0, 0, 0, 0.2);
}
.login .form .title {
  text-align: center;
  color: #313b5e;
}
.login .form .subtitle {
  text-align: center;
  color: #989dae;
  margin-bottom: 24px;
  font-size: 1.2rem;
}
.login .form .ant-card-head {
  min-height: unset;
  padding: unset;
  border-bottom: unset;
}
.login .form .ant-card-head .ant-card-extra {
  padding: unset;
}
.login .form .ant-card-body {
  height: 520px;
  width: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login .form .ant-card-body .ant-form-item-explain-error {
  font-size: 1.2rem;
}
.login .form .ant-card-body .ant-input-prefix {
  color: #5a72b1;
  margin-right: 12px;
}
.login .form .ant-card-body input::-moz-placeholder {
  color: #313b5e;
  font-size: 1.2rem;
}
.login .form .ant-card-body input::placeholder {
  color: #313b5e;
  font-size: 1.2rem;
}
.login .form .ant-card-body .ant-input-affix-wrapper:focus {
  box-shadow: 0 0 0 2px #869cbf;
}
.login .form .ant-card-body .ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px #869cbf;
}
.login .form .forgot {
  float: right;
}
.login .form .submit {
  width: 100%;
  border-radius: 5px;
  font-size: 1.2rem;
}

.market-projects .title-extra {
  display: flex;
  font-weight: 600;
}
.market-projects .header .filters {
  width: 100% !important;
}
.market-projects .title-extra .segmented-switch {
  margin-right: 1.2rem;
}
.market-projects .title-extra .icon {
  font-size: 2.2rem;
}
.market-projects .report-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.market-projects .report-container .tables-and-map {
  flex-grow: 1;
}
.market-projects .report-container .tables-and-map .tables {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.market-projects .report-container .tables-and-map .tables .table-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.market-projects
  .report-container
  .tables-and-map
  .tables
  .table-wrapper
  .ant-card-body {
  flex-grow: 1;
}
.market-projects
  .report-container
  .tables-and-map
  .tables
  .table-wrapper
  .ant-card-body
  .table-row {
  cursor: pointer;
}
.market-projects
  .report-container
  .tables-and-map
  .tables
  .table-wrapper
  .ant-card-body
  .ant-table-wrapper,
.market-projects
  .report-container
  .tables-and-map
  .tables
  .table-wrapper
  .ant-card-body
  .ant-spin-nested-loading,
.market-projects
  .report-container
  .tables-and-map
  .tables
  .table-wrapper
  .ant-card-body
  .ant-spin-container,
.market-projects
  .report-container
  .tables-and-map
  .tables
  .table-wrapper
  .ant-card-body
  .ant-table,
.market-projects
  .report-container
  .tables-and-map
  .tables
  .table-wrapper
  .ant-card-body
  .ant-table-container {
  height: 100%;
}
.market-projects
  .report-container
  .tables-and-map
  .tables
  .table-wrapper
  .ant-card-body
  .ant-table-container {
  display: flex;
  flex-direction: column;
}
.market-projects
  .report-container
  .tables-and-map
  .tables
  .table-wrapper
  .ant-card-body
  .ant-table-container
  .ant-table-body {
  flex: 1 1 auto;
}

.market-dashboard .report-container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.market-dashboard .report-container .market-share {
  display: flex;
  flex-direction: column;
}

.market-dashboard .report-container .market-share .ant-select {
  width: 22%;
}

.add-report-modal {
  flex-direction: column;
  border-radius: 15px;
  width: 800px !important;
}
.add-report-modal .ant-modal-content {
  border-radius: 30px;
  display: flex;
}

.add-report-modal .ant-modal-content .ant-modal-body {
  display: flex;
  width: 100% !important;
}

.add-report-modal .ant-modal-content .form {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.add-report-modal .ant-modal-content .form .ant-tag {
  display: flex !important;
  align-items: center;
}

.add-report-modal .ant-modal-content .form .ant-tag:hover {
  background-color: rgba(126, 146, 189, 0.5);
}

.add-report-modal .ant-modal-content .form .ant-select,
.add-report-modal .ant-modal-content .form .ant-select-item,
.add-report-modal .ant-modal-content .form .ant-input,
.add-report-modal .ant-modal-content .form .ant-form-item-label > label {
  font-size: 15px;
  font-weight: 600;
}
.add-report-modal .ant-modal-content .form .ant-form-item-label > label,
.add-report-modal .ant-modal-content .form .ant-input {
  color: #313b5e;
}
.add-report-modal .ant-modal-content .form .ant-form-item-label {
  font-weight: 600;
}

.add-report-modal .ant-modal-content .form .footer {
  display: flex;
  flex: 1;
  justify-content: center;
}

.add-report-modal .ant-modal-content .form .footer .submit-btn {
  font-weight: 600;
  margin: 5% 2.5%;
  color: #313b5e;
}
.add-report-modal .ant-modal-content .form .footer .ant-btn:focus {
  box-shadow: 0;
}

.include-icon {
  font-size: 20px;
  cursor: pointer;
}
.include-icon.true {
  color: #5a72b1;
}
.include-icon.true:hover {
  color: #c8cbd0;
}
.include-icon.false {
  color: #c8cbd0;
}
.include-icon.false:hover {
  color: #5a72b1;
}

.reports {
  display: flex;
  flex-flow: row wrap;
  row-gap: 16px;
}
.reports .add-report-card {
  text-align: center;
  height: 100%;
}
.reports .add-report-card .ant-card-body {
  height: 100%;
}
.reports .add-report-card .ant-card-body .title {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reports .report-card {
  height: 100%;
  min-height: 70px;
  cursor: pointer;
  width: 200px;
  font-family: Poppins, Medium;
  color: #313b5e;
}
.reports .report-card.selected {
  outline: 3px solid #c8cbd0;
}
.reports .report-card .actions-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: auto;
  height: 24px;
  width: 24px;
  font-size: 24px;
  border-radius: 50px;
}
.reports .report-card .actions-dots:hover {
  background-color: #c8cbd0;
}
.reports .report-card .ant-card-body {
  padding: 1.2rem 18px;
}
.reports .report-card .title {
  font-size: 1.5rem;
  font-weight: 600;
}
.reports .report-card .subtitle {
  font-size: 1.2rem;
}
.reports .report-card .area {
  font-size: 1.2rem;
  position: absolute;
  bottom: 0.6rem;
  right: 0.6rem;
  border-radius: 1.45rem;
  padding: 0.2rem 0.6rem;
  background-color: #dedede;
}

.market-project-detail {
  font-family: Poppins;
  color: #313b5e;
}
.market-project-detail .tab {
  padding: 0 12px;
}
.market-project-detail .single-project {
  padding: 12px;
}
.market-project-detail .ant-table {
  color: #313b5e;
}
.market-project-detail .ant-table-thead > tr > th {
  color: #313b5e;
  background-color: #fff;
}
.market-project-detail
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0px;
}
.market-project-detail .market-project-detail-header {
  font-size: 14px;
  margin-bottom: 18px;
}
.market-project-detail .market-project-detail-header .inner-header {
  margin-bottom: 12px;
}
.market-project-detail .market-project-detail-header .title-container {
  display: flex;
  justify-content: space-between;
}
.market-project-detail .market-project-detail-header .title-container .title {
  font-size: 20px;
  font-weight: 600;
}
.market-project-detail .market-project-detail-header .title-container .title a {
  color: #313b5e;
}
.market-project-detail
  .market-project-detail-header
  .title-container
  .title
  a:hover {
  color: #5a72b1;
}

.actions-btns {
  position: absolute;
  bottom: 60px;
  right: 50px;
}
.actions-btns .ant-btn-primary:hover,
.actions-btns .ant-btn-primary:focus {
  color: #fff;
}
.actions-btns .save-btn {
  margin-left: 12px;
}

.actions-btns .reset-btn {
  margin-left: 12px;
  background-color: #313b5e;
  color: #fff;
}

.report-actions .ant-popover-inner-content {
  padding: 0;
}
.report-actions .actions .item {
  width: 100%;
  text-align: center;
}

.project-link-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.project-link-container .project-link {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
}
.project-link-container .project-link a {
  font-size: 18px;
  color: #313b5e;
}

.map-container {
  position: relative;
  min-height: 300px;
  height: 100%;
  width: 100%;
}
.map-container.block {
  border-radius: 1.6rem 1.6rem 0 0;
}
.map-container .map-search {
  position: absolute;
  top: 5px;
  width: 200px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.map-container .map-search .ant-select-selector {
  border-radius: 25px;
}

.map-info-window-hover-content {
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
}

.map-info-window-hidden-exit-button .gm-ui-hover-effect {
  display: none;
  visibility: hidden;
}

.project-detail .ant-card {
  height: 100%;
}
.project-detail .preview {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.project-detail .preview .title-container {
  display: flex;
  justify-content: space-between;
}
.project-detail .preview .title-container .title {
  font-size: 20px;
  font-weight: 600;
}
.project-detail .preview .ant-card-cover {
  flex-grow: 1;
}
.project-detail .preview .ant-card-cover .ant-image {
  height: 100%;
}
.project-detail .preview .ant-card-cover img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.project-detail .chart-container {
  width: 100%;
}
.project-detail .center {
  margin: 1.2rem auto;
  width: 100%;
}
.project-detail .ant-segmented {
  border-radius: 25px !important;
  border: 1px solid #bdbdbd;
  background-color: #F1F1F1;
}
.project-detail .ant-segmented-item {
  border-radius: 25px !important;
}
.project-detail .ant-segmented-item-selected {
  box-shadow: 2px 0px 10px 2px #00000026;
}
.project-detail .ant-segmented-item-label {
  color: #828282 !important;
  font-family: Poppins, Medium;
}

.project-detail .ant-segmented-item-selected .ant-segmented-item-label {
  color: #313b5e !important;
  font-family: Poppins, Medium;
}

.project-detail .ant-segmented-thumb {
  border-radius: 25px !important;
  box-shadow: 2px 0px 10px 2px #00000026;
}

input,
button {
  font-family: Poppins;
}

.app,
#app {
  width: 100%;
  height: 100%;
}
.app .header,
#app .header {
  height: 48px;
  line-height: 48px;
  background: #fff;
}
.app .title-bar,
#app .title-bar {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-top: 1px solid #f0f0f0;
  align-items: center;
  user-select: none;
  font-weight: 700;
}
.app .title-bar svg,
#app .title-bar svg {
  font-size: 23px;
}
.app .title-bar .language-selector,
#app .title-bar .language-selector {
  margin: auto 0;
}
.app .title-bar .language-selector button,
#app .title-bar .language-selector button {
  border: 0;
}
.app .page-layout,
#app .page-layout {
  overflow: visible;
}
.app .title-bar .ant-menu-submenu-title,
#app .title-bar .ant-menu-submenu-title {
  display: flex;
  align-items: center;
}
.app .page,
#app .page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.form-header {
  font-size: 14px;
  color: #828282;
}
.form-header .form-header-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}
.form-header .form-header-item .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background: #bdbdbd;
  color: #ffffff;
  text-align: center;
}
.form-header .form-header-item.active .icon {
  background: #5a72b1;
}
.form-header .form-header-item.active {
  color: #313b5e;
  font-weight: 600;
}
.app .page .header,
#app .page .header {
  line-height: 36px;
  padding: 3px 50px;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
}
.app .page .header .reports-submenu .reports-submenu-item,
#app .page .header .reports-submenu .reports-submenu-item {
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 14px;
  color: #828282;
  font-weight: 400;
  border: 1.5px solid #bdbdbd;
  border-radius: 30px;
  box-sizing: border-box;
  padding: 4px;
  line-height: 30px;
  transition: color 0.3s ease-in-out;
}
.app .page .header .reports-submenu a.active .reports-submenu-item,
#app .page .header .reports-submenu a.active .reports-submenu-item,
.app .page .header .reports-submenu a:hover .reports-submenu-item,
#app .page .header .reports-submenu a:hover .reports-submenu-item {
  color: #313b5e;
  font-weight: 600;
  border-color: #313b5e;
}
.app .page .header .reports-submenu a.active .reports-submenu-item .icon,
#app .page .header .reports-submenu a.active .reports-submenu-item .icon,
.app .page .header .reports-submenu a:hover .reports-submenu-item .icon,
#app .page .header .reports-submenu a:hover .reports-submenu-item .icon {
  background: #5a72b1;
}
.app .page .header:has(.reports-submenu),
#app .page .header:has(.reports-submenu) {
  height: 60px;
  padding: 8px 50px;
  justify-content: flex-start;
  gap: 3rem;
}
.app .page .header:has(.reports-submenu) .filters,
#app .page .header:has(.reports-submenu) .filters {
  margin-left: auto;
}
.app .page .header .reports-submenu,
#app .page .header .reports-submenu {
  gap: 14px;
}
.app .page .header .reports-submenu .reports-submenu-item .icon,
#app .page .header .reports-submenu .reports-submenu-item .icon {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  background-color: #bdbdbd;
  border-radius: 30px;
  color: white;
  transition: background-color 0.3s ease-in-out;
}
.app .page .header .reports-submenu .reports-submenu-item svg,
#app .page .header .reports-submenu .reports-submenu-item svg {
  width: 24px;
  height: 24px;
}
.app .page .header .reports-submenu .reports-submenu-item .title,
#app .page .header .reports-submenu .reports-submenu-item .title {
  margin-right: 14px;
}
.app .page .header .title,
#app .page .header .title {
  float: left;
  font-size: 20px;
  height: 100%;
  display: flex;
  align-items: center;
}
.app .page .header .title .reports-title,
#app .page .header .title .reports-title {
  float: left;
  color: #828282;
  font-size: 14px;
  display: flex;
  gap: 4px;
}
.app .page .header .title .reports-title .active,
#app .page .header .title .reports-title .active {
  font-weight: 700;
  color: #313b5e;
}
.app .page .header .filters,
#app .page .header .filters {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.app .page .header .filters > div,
#app .page .header .filters > div {
  margin: 0 6px;
}
.app .page .content,
#app .page .content {
  flex: 1 1 auto;
  padding: 1.25rem;
  width: 100%;
  overflow: auto;
}

#app .side-bar .ant-menu-item .ant-menu-item-icon {
  width: 24px;
  height: 24px;
}
#app
  .side-bar
  .ant-menu-item.ant-menu-item-disabled:has(.ant-menu-title-content
    .ant-divider) {
  cursor: auto;
}
.app .side-bar,
#app .side-bar {
  background-color: #fff;
  border-right: 1px solid #f0f0f0;
  user-select: none;
}
.app .side-bar .ant-menu-sub.ant-menu-inline,
#app .side-bar .ant-menu-sub.ant-menu-inline {
  background-color: #fff;
}
.app .side-bar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
#app .side-bar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(158, 185, 218, 0.2);
  color: #313b5e;
}
.app .side-bar .ant-menu-submenu-title a,
#app .side-bar .ant-menu-submenu-title a {
  color: inherit;
}
#app .side-bar .ant-menu-item:has(.ant-menu-title-content .sub-items) {
  color: #828282;
}
#app .side-bar .ant-menu-item button.sub-items {
  border: none;
  background: transparent;
  color: #828282;
  font-weight: 600;
  font-size: 14px;
  padding: 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
.app .side-bar .ant-menu-item,
.app .side-bar .ant-menu-submenu-title,
#app .side-bar .ant-menu-item,
#app .side-bar .ant-menu-submenu-title {
  font-weight: 600;
}
.app .side-bar .ant-menu-sub .ant-menu-item,
#app .side-bar .ant-menu-sub .ant-menu-item {
  font-weight: 100;
}
.app .side-bar ul,
#app .side-bar ul {
  border: 0;
}
.app .side-bar .logo,
#app .side-bar .logo {
  font-weight: bold;
  text-align: center;
  font-size: 21px;
  vertical-align: middle;
  color: #313b5e;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 12px;
  padding: 8px 2px;
}
.app .side-bar .upload-container,
#app .side-bar .upload-container {
  margin-top: 48px;
  padding: 12px;
}
.app
  .side-bar
  .upload-container
  .ant-upload.ant-upload-drag
  p.ant-upload-drag-icon
  .anticon,
#app
  .side-bar
  .upload-container
  .ant-upload.ant-upload-drag
  p.ant-upload-drag-icon
  .anticon {
  color: #313b5e;
}
.app
  .side-bar
  .upload-container
  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover,
#app
  .side-bar
  .upload-container
  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #313b5e;
}
.app .content,
#app .content {
  min-height: 280px;
  height: auto;
  background-color: #f8f8f8;
  width: 100%;
}
.app b,
#app b {
  font-weight: bold;
}

.daily_news_kpi_icon {
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.daily_news_kpi_icon.supply {
  background-color: #9eb9da;
}

.daily_news_kpi_icon.demand {
  background-color: #c9cbd0;
}

.daily_news_kpi_icon img {
  width: 60%;
}

.kpi.daily_news.horizontal {
  min-height: 20rem;
}

.kpi.daily_news.horizontal .ant-card {
  height: 100%;
}

.kpi.daily_news.horizontal .ant-card .ant-card-body {
  height: 100%;
}

.kpi.daily_news.horizontal .ant-card .ant-card-body .kpi-container {
  height: 100%;
  box-sizing: border-box;
  padding: 24px 0;
  margin-top: 0;
}

.kpi.daily_news.horizontal .ant-card .ant-card-body .kpi-container .ant-col {
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  box-sizing: border-box;
  border-left: solid 1px #dedede;
}

.ant-collapse-expand-icon {
  align-self: center;
  color: #bdbdbd;
}

.ant-collapse-expand-icon * > svg {
  width: 1.5rem;
  height: 1.5rem;
}

.kpi.daily_news.horizontal
  .ant-card
  .ant-card-body
  .kpi-container
  .ant-col:first-of-type {
  border-left: none;
}

.kpi.daily_news.horizontal
  .ant-card
  .ant-card-body
  .kpi-container
  .ant-col
  .icon {
  font-size: 26px;
}

.daily_news_list .daily_news_list_item {
  margin: 1.2rem 0;
}

.daily_news_list .daily_news_list_item .daily_news_list_item_date {
  color: #bdbdbd;
  font-size: 1.2rem;
  line-height: 18px;
  display: flex;
  min-width: 50px;
}

.daily_news_list .daily_news_list_item:first-of-type {
  margin-top: 3rem;
}

.daily_news_list .daily_news_list_item:last-of-type {
  margin-bottom: 7rem;
}

.daily_news_list_item_container_value .daily_news_list_item_value {
  border-radius: 2rem;
  width: fit-content;
  color: #313b5e;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.project_card_badge_container.border {
  border: solid 1px #828282;
  display: flex;
  padding: 0px 10px;
  align-items: center;
  justify-content: center;
  min-height: 29px;
  border-radius: 2rem;
  width: fit-content;
  color: #313b5e;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.project_card_badge_container_border {
  border: solid 1px #828282;
  border-radius: 2rem;
  padding: 5px 5px;
  box-sizing: border-box;
}

.daily_news_list_item_container_value .daily_news_list_item_value.border {
  border: solid 1px #bdbdbd;
  padding: 3px 9px 3px 3px;
  box-sizing: border-box;
}

.daily_news_list_item_value_border {
  border: solid 1px #bdbdbd;
  border-radius: 2rem;
  padding: 5px 5px;
  box-sizing: border-box;
}

.kpi.daily_news.vertical .ant-card .ant-card-body {
  height: 100%;
}

.kpi.daily_news.vertical .ant-card .ant-card-body .kpi-container {
  height: 100%;
}

.kpi.daily_news.vertical .ant-card .ant-card-body .kpi-container > .ant-col {
  height: 100%;
}

.kpi.daily_news.vertical
  .ant-card
  .ant-card-body
  .kpi-container
  .infinite-scroll-container {
  height: 80%;
  overflow: hidden;
}

.kpi.daily_news.vertical
  .ant-card
  .ant-card-body
  .kpi-container
  .infinite-scroll-container
  .infinite-container {
  height: 100%;
  overflow-y: scroll;
}

.daily_news_popover {
  min-width: 12rem;
}

.daily_news_area span.ant-select-selection-item {
  font-weight: 700 !important;
}

.daily_news_list_item_trans {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.daily_news_list_item_value.hoverable {
  cursor: pointer;
}

.daily_news_list_item_value.hoverable:hover {
  background: #f0f0f0;
}

.dashboard
  .dashboard-container
  .kpi.daily_news.vertical
  .daily_news_kpi_competitors_news.title {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
  background: #9eb9da;
  margin-right: 1rem;
}

.daily_news_icon {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.daily_news_icon img {
  width: 60%;
  height: 60%;
}

.daily_news_icon.light_blue {
  background: #9eb9da;
}

.daily_news_icon.dark_blue {
  background: #869cbf;
}

.daily_news_icon.grey {
  background: #bdbdbd;
}

.daily_news_popover,
.daily_news_popover div {
  color: #313b5e;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 18px;
}

.daily_news_popover .title {
  font-weight: 700;
}

.daily_news_popover .value {
  text-align: right;
}

.infinite-container::-webkit-scrollbar {
  width: 7px;
  height: 0;
}
.infinite-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}
.infinite-container:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.45);
}
.infinite-container::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.55);
}

.kpi.daily_news.vertical {
  height: calc(50% - 16px);
}

/* .kpi.daily_news.vertical:first-of-type {
  margin-bottom: 24px;
} */

.dashboard .dashboard-container .kpi.daily_news .title {
  font-weight: 400;
  color: #313b5e;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.dashboard .dashboard-container .kpi.daily_news.vertical .title {
  font-weight: 500;
  color: #313b5e;
  font-size: 24px;
  line-height: 36px;
}

.dashboard .dashboard-container .kpi.daily_news.vertical .subtitle {
  font-weight: 500;
  color: #bdbdbd;
  font-size: 1.2rem;
}

.dashboard .dashboard-container .kpi.daily_news .title.bold {
  font-weight: 700;
}

.dashboard .dashboard-container .kpi.daily_news .icon {
  font-weight: 500;
  color: #313b5e;
}

.dashboard .dashboard-container .kpi.daily_news .subtitle {
  font-weight: 400;
  color: #828282;
}

.chevron.dark {
  font-weight: 400;
  color: #313b5e !important;
}

.chevron.light {
  font-weight: 400;
  color: #828282 !important;
}

.chevron-label.light {
  font-weight: 400;
  margin-right: '8px';
  color: #828282 !important;
}

.chevron-label.dark {
  font-weight: 400;
  margin-right: '8px';
  color: #313b5e !important;
}

@media screen and (max-width: 1600px) {
  .daily_news_kpi_icon {
    width: 6rem;
    height: 6rem;
  }

  .kpi.daily_news.horizontal
    .ant-card
    .ant-card-body
    .kpi-container
    .ant-col
    .icon {
    font-size: 22px;
  }

  .kpi.daily_news.horizontal
    .ant-card
    .ant-card-body
    .kpi-container
    .ant-col
    .title {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 1400px) {
  .daily_news_kpi_icon {
    width: 5rem;
    height: 5rem;
  }

  .kpi.daily_news.horizontal {
    min-height: 15rem;
  }

  .kpi.daily_news.horizontal
    .ant-card
    .ant-card-body
    .kpi-container
    .ant-col
    .icon {
    font-size: 18px;
  }

  .kpi.daily_news.horizontal
    .ant-card
    .ant-card-body
    .kpi-container
    .ant-col
    .title {
    font-size: 1.2rem;
  }
}

.bm-modal .ant-modal-content .ant-modal-close {
  top: 30px;
  inset-inline-end: 30px;
}

.segmented.secondary {
  display: flex;
}

.market-share-date-picker .ant-select {
  width: 33% !important;
}

.secondary .market-share-date-picker {
  display: flex;
}

.secondary .market-share-date-picker .ant-select {
  width: auto !important;
}

.secondary .market-share-date-picker .ant-select:last-of-type {
  margin: 0 1.2rem;
}

.market-share-date-picker .ant-select:last-of-type {
  margin: 1.2rem;
}

.actions-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: auto;
  height: 24px;
  width: 24px;
  position: absolute;
  font-size: 24px;
  top: 6px;
  right: 6px;
  border-radius: 50px;
  cursor: pointer;
}

.actions-dots:hover {
  background-color: #c8cbd0;
}

.actions-dots-left {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  height: 28px;
  width: 28px;
  position: absolute;
  font-size: 1.2rem;
  top: -6px;
  left: -6px;
  border-radius: 50px;
  cursor: pointer;
}

.actions-dots-left:hover {
  background-color: #c8cbd0;
}

.actions-download {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  font-size: 24px;
  right: 0px;
  border-radius: 50px;
  cursor: pointer;
}

.actions-download-left {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  font-size: 24px;
  left: 0px;
  top: 0px;
  border-radius: 50px;
  cursor: pointer;
}

.actions-download:hover {
  background-color: #c8cbd0;
}

.custom-modal-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: auto;
  height: 24px;
  width: 24px;
  position: absolute;
  font-size: 24px;
  top: 6px;
  right: 6px;
  border-radius: 50px;
  cursor: pointer;
}

.report-actions .actions {
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.times {
  font-size: 3.1rem;
  font-weight: bold;
  cursor: pointer;
  color: #313b5e;
  transition: color 0.3s;
}

.times:hover {
  color: #69b1ff;
}

.gm-style-iw button.gm-ui-hover-effect {
  display: none !important;
}

.gm-style-iw {
  border-radius: 20px !important;
  padding: 2rem 2rem !important;
}

.gm-style-iw-d {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.gm-style-iw-ch {
  padding: 0rem !important;
}

.ant-segmented-item {
  color: #828282;
}

.ant-select .ant-select-selector .ant-select-selection-item {
  font-weight: 900;
}

.ant-segmented-item-label {
  font-weight: normal;
}

.ant-segmented-item-selected .ant-segmented-item-label {
  font-weight: 900;
}

.reports_overview_header {
  margin-bottom: 2rem;
}

.reports_overview_header .title {
  font-weight: 700;
  color: #313b5e;
  font-size: 14px;
}

.reports_overview_header .title.big {
  font-size: 30px;
}

.reports_overview_header .icon {
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.reports_overview_header .icon .daily_news_kpi_icon {
  width: 75px;
  height: 75px;
}

.reports_overview_header .icon img {
  width: 45px;
  height: 45px;
}

.reports_overview_header
  .ant-col:not(.reports_overview_header .ant-col:first-of-type) {
  border-left: solid 1px #dedede;
}

.reports_overview_header .ant-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ant-table {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.ant-table-summary .ant-table-cell {
  border: none !important
}

.ant-table-container {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.ant-table-content {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.ant-table-thead {
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.ant-table-wrapper .ant-table-content .ant-table-thead th.ant-table-cell {
  background: #ffffff;
  border-top-left-radius: 16px !important;
  border-top-right-radius: 16px !important;
}

.ant-table-wrapper
  .ant-table-content
  .ant-table-thead
  th.ant-table-cell::before {
  background-color: #ffffff !important;
}

.canva-embed-container {
  width: 100%;
  height: 100%;
  margin-top: 1.6em;
  margin-bottom: 0.9em;
}

.ant-tooltip {
  max-width: fit-content !important;
}

.ant-tooltip-inner {
  background-color: white !important;
  color: #313b5e !important;
  padding: 10px 15px !important;
  max-width: 1000px !important;
  border-radius: 16px !important;
  border: 1px solid #D9D9D9;
}

.ant-tooltip-arrow {
  display: none !important;
}

.ant-table-column-title {
  user-select: none;
}

.ant-table-filter-dropdown-btns .ant-btn-primary {
  border: 1px solid #bdbdbd;
  color: white;
  margin-left: 10px;
  border-radius: 15px;
  padding: 0px 10px;
  box-shadow: none;
}

.bm-submit-btn {
  border-radius: 15px;
  background-color: #5a72b1;
  border: none;
  box-shadow: none;
  font-size: 14px;
  color: white;
  font-weight: 700;
}

.bm-default-btn {
  border-radius: 15px;
  background-color: white;
  box-shadow: none;
  border: 1px solid #bdbdbd;
  font-size: 14px;
  color: #313b5e;
  font-weight: 400;
}

.bm-submit-btn:disabled,
.bm-default-btn:disabled {
  background-color: transparent;
  color: #bdbdbd;
  font-weight: 400;
  border: 1px solid #bdbdbd;
}

.ant-picker-input {
  cursor: pointer;
}

.filters-icon {
  width: 24px;
  height: 24px;
  stroke-width: 2px;
  color: #313b5e;
}

.filters-chevron-icon {
  width: 16px;
  height: 16px;
  stroke-width: 3px;
  color: #bdbdbd;
  cursor: pointer;
}

.ant-checkbox-input-inner {
  background-color: #d9d9d9 !important;
}

.history-content-bold {
  font-weight: bold;
}

.days-on-market-pm {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.hide-filters .ant-table-column-sorter {
  display: none;
}

.targets {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.targets .ant-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  height: 100%;
}

.plan {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
}

.plan .ant-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 100%;
}

.sales-modal {
  min-width: 120rem;
  user-select: none;
}

.sales-target-delete {
  min-width: 60rem;
}

.ant-select-selector {
  padding-left: 6px !important;
}

.sales-modal-select .ant-select-selector {
  padding: 0 !important;
  padding-right: 12px !important;
  color: #828282;
}

.sales-modal-select .ant-select-selection-item {
  font-weight: 400 !important;
  text-align: left !important;
}

.sales-modal .ant-input-number-input {
  padding: 0 !important;
  max-width: 9rem;
  color: #313b5e;
}

.bm-tooltip-btn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

.compare .ant-modal {
  width: 40rem !important;
}

.custom-select .ant-select-selection-item {
  display: flex;
  justify-content: space-between;
}

.custom-select .ant-select-selection-overflow {
  display: flex;
  flex-direction: column;
}

.custom-select .ant-select-selection-overflow-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bm-icon {
  stroke-width: 2px;
}

.daily-news-reports-selector .ant-select-selector {
  border-radius: 15px;
  min-width: 20rem;
}

.daily-news-reports-selector .ant-select-selection-item {
  text-align: start !important;
}

.scrollbar-hide {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.segmented-controls-wrapper {
  @apply flex gap-3 select-none flex-wrap justify-start items-center;
}

.chevron-paginate {
  @apply stroke-2 w-12 h-12 text-gray-400 cursor-pointer;
}

.chevron-paginate:hover {
  @apply text-gray-600;
}

.price-list-table .ant-table-cell {
  padding: 10px !important;
}

.price-list .price-list-floor {
  display: flex;
  height: 100%;
  overflow: hidden;
  border-radius: 16px !important;
  position: relative;
}

.price-list .price-list-floor .ant-card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}

.select-ant-select {
  position: relative;
  outline: none !important;
}

.select-ant-select .ant-select-selector {
  border: none !important;
  border-radius: 15px !important;
  outline: none;
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;
  justify-items: center !important;
}

.select-ant-select .ant-select-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.select-ant-select .ant-select-dropdown {
  width: 100% !important;
  left: 0 !important;
}

.select-ant-select .ant-select-dropdown-placement-bottomLeft {
  width: 100% !important;
}

.svg-container-width-or {
  max-width: 90rem;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.svg-container-height-or {
  max-width: 70rem;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.svg-element {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: contain;
}

.ant-popover-arrow {
  display: none !important;
}

.supply-history-modal .ant-pagination {
  margin-bottom: 0px !important;
}

.price-update .content {
  overflow: hidden !important;
  height: 100% !important;
}

.price-update 
.ant-card-body {
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
  overflow-y: hidden !important;
}

@media screen and (max-width: 1024px) {
  .app .title-bar,
  #app .title-bar {
    border: none;
  }

  .app .page .header,
  #app .page .header {
    padding: 6px 20px;
  }

  .ant-layout-header {
    padding: 6px 20px;
  }

  .custom-disabled * {
    color: #313b5e;
    cursor: default !important;
    font-weight: 700 !important;
    pointer-events: none !important;
  }

  .subtitle-container {
    margin: 0 !important;
  }

  .ant-table-cell {
    padding: 0px 5px !important;
  }
}

@media screen and (max-width: 1280px) {
  .app .side-bar,
  #app .side-bar {
    background-color: #fff;
    border-right: 1px solid #f0f0f0;
    user-select: none;
    position: absolute;
    left: 0;
    height: 100%;
    z-index: 999;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
}

@media screen and (max-height: 1280px) {
  .svg-container-width-or {
    max-width: 60rem;
  }

  .svg-container-height-or {
    max-width: 45rem;
  }
}